.firmwareCard{
  display: flex;
  justify-content: center;
  padding: 16px;
  height: 120px;
  width: 120px;
  background: white;
  border-radius: 12px;
  margin-right: 16px;
  box-shadow: 0.3px 0.4px 0.6px hsl(0deg 0% 70% / 0.33),
    2.2px 2.5px 5.1px -1px hsl(0deg 0% 70% / 0.41);
}

.firmwareCard:focus,
.firmwareCard.selected {
  border: 2px solid;
  border-color: #0C9EAB;
}